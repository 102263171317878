
import { mapState, mapGetters } from 'vuex';
import hasStickyData from '../../mixins/hasStickyData';

export default {
  title: 'NavigationSidebar',
  data() {
    return {
      menuExpanded: false,
      contractedMenu: '',
      expandedMenu: '',
      contracted: {},

      navigationItems: [
        {
          to: '/dashboard',
          icon: 'fa-home',
          text: 'Dashboard',
          children: [
            {
              to: '/dashboard',
              text: 'My Overview',
              restricted: [],
            },
            {
              to: '/dashboard/my-logged-time',
              text: 'My Logged time',
              restricted: [],
            },
            {
              to: '/dashboard/my-tasks',
              text: 'My Tasks',
              restricted: [],
            },
            {
              to: '/dashboard/my-forms',
              text: 'My Forms',
              restricted: [],
            },
          ],
          restricted: [],
        },
        {
          to: '/contacts',
          icon: 'fa-users',
          text: 'Contacts',
          children: [
            {
              to: '/contacts',
              text: 'Contacts',
              restricted: [],
            },
            {
              to: '/circle',
              text: 'Circles',
              restricted: [],
            },
            {
              to: '/plans',
              text: 'Plans',
              restricted: [],
            },
          ],
          restricted: [],
        },
        {
          to: '/cases',
          icon: 'fa-briefcase',
          text: 'Cases',
          restricted: [],
        },
        {
          to: '/business',
          icon: 'fa-building',
          text: 'Business',
          children: [
            {
              to: '/business/key-metrics',
              text: 'Key Metrics',
              restricted: ['owners', 'admins'],
            },
            {
              to: '/automations',
              text: 'Automations',
              restricted: [],
            },
            {
              to: '/business/docs',
              text: 'Document Builder',
              restricted: [],
            },
            {
              to: '/business/forms',
              text: 'Form Builder',
              restricted: [],
            },
            {
              to: '/business/reviews',
              text: 'Reviews',
              restricted: [],
            },
            {
              to: '/business/signatures',
              text: 'Signature Requests',
              restricted: [],
            },
            {
              to: '/business/income-reconciliation',
              text: 'Income Reconciliation',
              restricted: ['owners', 'admins'],
            },
            {
              to: '/business/reporting',
              text: 'Reporting / Exports',
              restricted: ['owners', 'admins'],
            },
            {
              to: '/business/imports',
              text: 'Imports',
              restricted: ['owners', 'admins'],
            },
          ],
          restricted: [],
        },
        {
          to: '/settings',
          icon: 'fa-gear',
          text: 'Settings',
          restricted: ['owners', 'admins'],
        },
      ],
    };
  },
  mixins: [hasStickyData],
  computed: {
    ...mapState('navigation', ['clickInteraction']),

    ...mapGetters('user', [
      'isPortalUser',
      'isClient',
      'isEmployee',
      'isExternal',
      'isOwner',
      'isAdmin',
      'isMember',
      'isNotSeniorEmployee',
    ]),

    navigation() {
      return this.navigationItems.filter((parent) => this.filterLinks(parent));
    },
    navItems() {
      return this.items.filter((parent) => this.filterLinks(parent));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setInitialStates();
      window.onresize = this.widowResize;
    });
  },
  beforeDestroy() {
    window.onresize = null;
  },
  methods: {
    widowResize() {
      this.setInitialStates();
    },
    async setInitialStates() {
      let isOpen = await this.getStickyData('menuOpen');
      if (isOpen === true) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    },

    openMenu() {
      this.menuExpanded = true;
      this.setStickyData('menuOpen', true);
      let element = document.getElementById('__layout');
      element.classList.add('open');
      element.classList.remove('closed');
    },

    closeMenu() {
      this.menuExpanded = false;
      this.setStickyData('menuOpen', false);
      let element = document.getElementById('__layout');
      element.classList.add('closed');
      element.classList.remove('open');
    },

    switchMenu() {
      if (this.menuExpanded) {
        this.closeMenu();
      } else {
        this.openMenu();
      }
    },

    filterLinks(item) {
      if (item.hasOwnProperty('show')) {
        return item.show;
      }

      if (!item.hasOwnProperty('restricted') || !Array.isArray(item.restricted)) {
        return true;
      }

      if (item.restricted.some((r) => ['owners', 'admins'].includes(r)) && this.isNotSeniorEmployee) {
        return false;
      }

      if (item.hasOwnProperty('children') && Array.isArray(item.children)) {
        item.children = item.children.filter((child) => this.filterLinks(child));
      }

      return true;
    },
  },
};
