
import plansAPI from '~/api/plans';

export default {
  name: 'ClientPlanSelect',

  props: {
    value: {
      type: String | Array | Object | null,
      required: true,
    },
    client_uuid: {
      type: String | null,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentPlanUuid: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    preserveModelType: {
      type: Boolean,
      required: false,
    },
    exclude: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      selectedList: [],
      plans: [],
      plansMeta: [],
      page: 1,
      loadingMore: 'Loading more...',
      loading: false,
      board: {
        lists: [],
      },
    };
  },

  created() {
    this.grabPlans(this.client_uuid);
  },

  mounted() {
    this.createIntersectionObserver();
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  methods: {
    async grabPlans(uuid) {
      if (!uuid) return;

      this.loading = true;
      const rsp = await plansAPI.allForClient(uuid, { params: { per_page: 25, page: this.page, include: 'accounts' } });

      if (this.exclude.length) {
        rsp.data.data = rsp.data.data.filter((item) => !this.exclude.includes(item.type));
      }

      this.plans = rsp.data.data;
      this.plansMeta = rsp.data.meta;

      if (this.currentPlanUuid) {
        this.plans = rsp.data.data.filter((item) => item.uuid !== this.currentPlanUuid);
      } else {
        this.plans = rsp.data.data;
      }

      this.setSelectedValue(this.value);
      this.loading = false;
    },

    setSelectedValue(value) {
      let selected = null;

      if (Array.isArray(value)) {
        selected = value.map((item) => this.plans.find((plan) => item === plan.uuid));
      } else if (typeof value === 'object' && value?.uuid) {
        selected = this.plans.find((item) => value.uuid === item.uuid);
      } else if (value) {
        selected = this.plans.find((item) => value === item?.uuid);
      }

      this.selectedList = selected;
    },

    createIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.fetchNextPage();
          }
        });
      }, options);

      this.observer.observe(this.$refs.loadMoreTrigger);
    },

    async fetchNextPage() {
      if (this.page < this.plansMeta.last_page) {
        this.page++;
        this.loading = true;
        const rsp = await plansAPI.allForClient(this.client_uuid, { params: { per_page: 25, page: this.page } });
        this.plans = [...this.plans, ...rsp.data.data];
        this.plansMeta = rsp.data.meta;
        this.loading = false;
      }
      if (this.page === this.plansMeta.last_page) {
        this.loadingMore = '';
      }
    },
  },

  watch: {
    client_uuid(new_val) {
      this.grabPlans(new_val);
    },
    selectedList(new_val) {
      if (this.preserveModelType) {
        this.$emit('input', new_val);
      } else if (new_val === null) {
        this.$emit('input', null);
      } else if (!Array.isArray(new_val)) {
        if (new_val && new_val.uuid !== null) {
          this.$emit('input', new_val.uuid);
        }
      } else {
        let reduced = new_val.filter((item) => item && item.uuid !== null).map((item) => item.uuid);
        this.$emit('input', reduced);
      }
    },

    value: {
      async handler(new_val, old_val) {
        // two-way binding
        if (this.preserveModelType) {
          if (this.loading) {
            await this.grabPlans(this.client_uuid);
          }

          this.setSelectedValue(new_val);
        }
      },
    },
  },
};
