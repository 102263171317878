export default function (context) {
  context.$axios.onRequest((config) => {
    config.withCredentials = true
    config.headers.common['X-PLANNR-ACCOUNT-UUID'] = context.store.getters['user/currentAccountUuid']

    return config
  })

  context.$axios.onResponse((response) => {
    if(!response.config.url.includes('api/v1/state')) {
      const timeout = context.store.getters['user/getExpiryDate'];
      if (context.$dayjs(timeout).isValid()) {
        let now = context.$dayjs();
        let expires = context.$dayjs(timeout)
        let minutes = expires.diff(now, 'minutes');
        if (minutes <= 15) {
          context.$authService.refreshState();
        }
      }
    }
  })

  context.$axios.onError(async (error) => {
    const status = parseInt(error.response && error.response.status)

    if (context.$authService.loggedIn && status === 401) {
      await context.$authService.nuxtLogout()

      if (context.$authService.authStrategy === 'plannrProduction') {
        context.$authService.redirectToLaravel()
        return Promise.resolve()
      } else {
        context.redirect('/')
        return Promise.reject(error)
      }
    } else if (status === 500) {
      $nuxt.$emit(
        'notification',
        'Server error. Please contact info@plannrcrm.com if the error still persists.',
        'warning',
        true,
        10000
      )
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  })
}
