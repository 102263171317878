
export default {
  name: 'ErrorBlock',
  data() {
    return {
      errors: [],
    };
  },
  methods: {
    setErrors(data) {
      if (data && !Array.isArray(data) && typeof data === 'object') {
        // handle shape of { field: [message] }
        data = this.$helpers.getUniqueErrorMessages(data);
      }

      this.errors = data;
    },
    clear() {
      this.errors = [];
    },
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
  },
  watch: {
    errors: function() {
      if (this.hasErrors) {
        setTimeout(() => this.$el.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100);
      }
    }
  }
};
